import axios from "/@/utils/request";

const api = {
  getPromoList: '/base/comm/getPromotionRewardList',
  getPromoDetail: '/base/comm/getPromotionRewardDetails',
  getPromoInfo: '/base/comm/getPromoInfo',
};

export const getPromoList = (params: any) => {
  return axios({
    url: api.getPromoList,
    method: 'post',
    data: params,
  });
};

export const getPromoDetail = (params: any) => {
  return axios({
    url: api.getPromoDetail,
    method: 'post',
    data: params,
  });
};

export const getPromoInfo = () => {
  return axios({
    url: api.getPromoInfo,
    method: 'get',
  });
};