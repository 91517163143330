export default {
  promoDetail1: 'Transaction details',
  promoDetail2: 'Payment time',
  promoDetail3: 'Payment type',
  promoDetail4: 'Payment amount(U)',
  promoDetail5: 'Your commission(U)',
  promoDetail6: 'Commission status',
  promoDetail7: 'Payment in progress',
  promoDetail8: 'Already arrived',
  promoDetail9: 'Withdrawn',
  promoDetail10: 'First transaction',
  promoDetail11: 'Renewal',
  promoDetail12: 'Transaction details',
};
