import { inject } from 'vue'
import api from '/@/api'
import { useRouter, useRoute } from 'vue-router'

export default function useCommon() {
  const router:any = useRouter()
  const route:any = useRoute()
  const message:any = inject('message')
  const emitter:any = inject('emitter')

  return {
    router,
    route,
    message,
    reqApi: api,
    emitter
  }
}