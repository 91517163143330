import { createApp } from "vue";
import App from "/@/App.vue";
import ElementPlus from "element-plus";
import direct from "/@/directive/index";
import router from "/@/router/index";
import { pinia } from "/@/store";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/display.css";
import "/@/permission";
import "/@/assets/css/index.css";
import components from "/@/components";
import "nprogress/nprogress.css";
import "virtual:svg-icons-register";
import SvgIcon from "/@/components/SvnIcon/index.vue";
import i18n from "/@/config/i18n";
import vue3GoogleLogin from "vue3-google-login";

import * as ElIcons from "@element-plus/icons-vue";

const app = createApp(App);
direct(app);
app.use(ElementPlus);
app.use(components);
app.use(router);
app.use(pinia);
app.use(i18n);
app.component("SvgIcon", SvgIcon);

app.use(vue3GoogleLogin, {
  clientId: import.meta.env.VITE_GOOGLE_ID,
});

const ElIconsData = ElIcons as unknown as Array<
    () => Promise<typeof import("*.vue")>
>;
for (const iconName in ElIconsData) {
  app.component(`ElIcon${iconName}`, ElIconsData[iconName]);
}

app.mount("#app");
