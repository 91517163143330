export default {
  arrearsReminder: {
    components1: 'Dear Unicorn SCRM customer, your account is about to expire!',
    components2:
      'Unicorn reminds you to renew in time to avoid unnecessary losses!',
    components3: 'Day',
    components4: 'Hour',
    components5: 'I already know, renew immediately',
    components6:
      '---------------------------- Surplus ----------------------------',
    components7: 'I already know, renew immediately',
  },
  navbar: {
    components1: 'Change Password',
    components2: 'Sign out',
    components3: 'Download APP',
    components4: 'Ports:',
    components5: 'Date of Expiry:',
    components6: 'Remaining Time Until Expiration：',
    components7: 'Expired, please renew in time',
    components8: 'Contact Us',
    components9: 'Recharge',
    components10: 'User Guide',
    components11: 'Day',
    components12: 'Characters remaining',
    components13: 'Package type：',
    components14: 'Expiring soon',
    components15: 'Expired',
    components16:
      'After the package expires, {combo}{port} ports will take effect immediately in {day} days',
    components17: 'Buy a package',
    components18: 'Renewal',
    components19: 'Add port',
    components20: 'Change package',
    components21: 'Free version',
    components22: 'Professional version',
    components23: 'Ultimate version',
    components24: 'Character version',
    components25: 'Character version pro',
  },
  combo: {
    components1: 'rights and interests',
    components2: 'unit price',
    components3: 'Purchase port number',
    components4: 'Purchase quantity',
    components5: 'U/port/mo. (1 mo. 30 days)',
    components6: 'fold',
    components7: 'd',
    components8: 'U/port/mo.',
    components9: 'character',
    components10: 'No account limit, no port limit',
    components11: 'Expiration time',
    components12: 'Number of characters',
    components13: 'Added {num} ports to the flagship version for {day} days',
    components14: 'Payment platform',
    components15: 'Payment amount',
    components16: 'Pay now',
    components17: 'and the number of ports increased from {num1} to {num}',
    components18: 'Details',
    components19: 'Total details',
    components20: 'Discount amount',
    components21: 'Deduction amount',
    components22: 'Total actual payment',
    components23:
      'After the payment is successful, the package will be recharged successfully within 2 minutes. Please wait patiently.',
    components24: 'Confirm',
    components25: 'Purchase failed',
    components26: 'Supplementary port number',
  },
};
