export default {
    workOrderList1:"Fan Count Ticket",
    workOrderList2:"Invitation Code",
    workOrderList3:"Please enter",
    workOrderList4:"Note",
    workOrderList5:"Please enter",
    workOrderList6:"Fan Count Ticket Number",
    workOrderList7:"Please enter",
    workOrderList8:"Search",
    workOrderList9:"Reset",
    workOrderList10:"Fan Count Ticket Number",
    workOrderList11:"Turn on",
    workOrderList12:"Close",
    workOrderList13:"Operate",
    workOrderList14:"Ticket Details",
    workOrderList15:"Share Ticket",
    workOrderList16:"Edit Ticket",
    workOrderList17:"Reset Ticket Data",
    workOrderList18:"Close",
    workOrderList19:"Delete",
    workOrderList20:"Delete task",
    workOrderList21:"Are you sure you want to delete it?？",
    workOrderList22:"Successful operation",
    workOrderList23:"Fan count ticket link copied successfully",
    workOrderList24:"Share Ticket Link",
    workOrderList25:"Edit Ticket",
    workOrderList26:"Reset",
    workOrderList27:"Are you sure you want to reset?？",
    workOrderList28:"Fan count ticket number",
    workOrderList29:"Fan Count Ticket Name",
    workOrderList30:"Fan Count Ticket Notes",
    workOrderList31:"Corresponding Invitation Code",
    workOrderList32:"Ticket password",
    workOrderList33:"Creation Time",
    workOrderList34:"Fan Count ticket Status",
    workOrderList35:"Completed/Total Goal (Duplication Removed)",
    workOrderList36:"Total Number of Fans Duplicated",
    workOrderList37:"New Fans Count for Today(Duplication Removed)",
    workOrderList38:"Status",
    workOrderList39:"Close ticket",
    workOrderList40:"Whether to close the fan count ticket. After closing, the shared fan count ticket page will not be able to be logged in, and the account diversion in your fan count ticket will be invalid.！"
}