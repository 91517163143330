import request from '/@/utils/request'
import { AxiosResponse } from 'axios'

const api = {
  login: '/base/open/agentLogin',
  getPlatformAuth: '/base/comm/getThirdPartyInit',
  loginOut: '/base/comm/agentLoginOut',
  getRefreshToken: '/base/comm/agentRefreshToken',
  getPortsNum: '/base/comm/getAgentPortInfo',
  updatePwd: '/base/comm/changeLoginPassword',
  updatRemark: '/base/comm/UpdateAccountRemark',
  register: '/base/open/createAgent',
  reset: '/base/open/resetAgentPwd',
  SendEmailcode: '/base/open/sendEmail',
  ResetEmailcode: '/base/open/restSendEmail',
  InitChannelInfo: 'base/open/initChannelInfo',
  GetComboConf: '/base/comm/getComboConf',
  CreateOrder: '/base/comm/createOrder',
  UpdatePromoCode: '/base/comm/updatePromoLink',
};

export interface loginParam {
  username: string;
  password: string;
}

export interface registerParam {
  userName: string;
  passWord: string;
  code: string;
}

export function login(
  param: loginParam
): Promise<AxiosResponse<IResponse<string>>> {
  return request({
    url: api.login,
    method: "post",
    data: param,
  });
}

export function register(
  param: registerParam
): Promise<AxiosResponse<IResponse<string>>> {
  return request({
    url: api.register,
    method: "post",
    data: param,
  });
}

export function reset(
  param: registerParam
): Promise<AxiosResponse<IResponse<string>>> {
  return request({
    url: api.reset,
    method: "post",
    data: param,
  });
}

export function emailcodeApi(
  param: any
): Promise<AxiosResponse<IResponse<string>>> {
  return request({
    url: api.SendEmailcode,
    method: "post",
    data: param,
  });
}

export function resetEmailcodeApi(
  param: any
): Promise<AxiosResponse<IResponse<string>>> {
  return request({
    url: api.ResetEmailcode,
    method: "post",
    data: param,
  });
}

export function getPlatformAuth(): Promise<AxiosResponse<IResponse<string>>> {
  return request({
    url: api.getPlatformAuth,
    method: "get",
  });
}

export function loginOut(): Promise<AxiosResponse<IResponse<string>>> {
  return request({
    url: api.loginOut,
    method: "get",
  });
}

export function refreshToken(): Promise<AxiosResponse<IResponse<string>>> {
  return request({
    url: api.getRefreshToken,
    method: "get",
  });
}

export function getPortsNum(): Promise<AxiosResponse<IResponse<string>>> {
  return request({
    url: api.getPortsNum,
    method: "get",
  });
}

export function initPlatformInfo(): Promise<AxiosResponse<IResponse<string>>> {
  return request({
    url: api.InitChannelInfo,
    method: "get",
  });
}

const updatePwd = (param: any) => {
  return request({
    url: api.updatePwd,
    method: "post",
    data: param,
  });
};
const updatRemark = (param: any) => {
  return request({
    url: api.updatRemark,
    method: "post",
    data: param,
  });
};

export const googleAuthLogin = (accessToken: string, promotionCode:string) => {
  return request({
    url: '/base/open/googleAuth',
    method: 'post',
    data: {
      accessToken: accessToken,
      promotionCode: promotionCode,
    },
  });
};

export interface TelegramReq {
  id: number;
  first_name: string;
  username: string;
  photo_url: string;
  auth_date: number;
  hash: string;
}

export const telegramAuthLogin = (data: TelegramReq) => {
  return request({
    url: "/base/open/telegramAuth",
    method: "post",
    data,
  });
};

export const telegramAuthVerify = (data: TelegramReq) => {
  return request({
    url: "/base/open/telegramAuthVerify",
    method: "post",
    data,
  });
};

export const payCombo = (param: any) => {
  return request({
    url: api.CreateOrder,
    method: 'post',
    data: param,
  });
};

const  getComboConf=(params:any) =>{
  return request({
    url: api.GetComboConf,
    method: 'get',
    params
  });
}
const updatePromoCode=(params:any)=>{
  return request({
    url: api.UpdatePromoCode,
    method: 'get',
    params
  });
}

export { updatePwd, updatRemark, getComboConf, updatePromoCode };
