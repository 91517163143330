<template>
  <div class="com_avatar">
      <el-avatar :shape="props.shape" :size='props.size' :src='props.src' @error="errorHandler" @click="lookImg">
            <img :src="props.errorSrc" />
      </el-avatar>
      <el-dialog v-model="operState.show" title="" 
        custom-class="rounded-lg avatar_dialog_style" width="26%" :show-close="false">
        <template #title  >
          <div class="d_header"> </div>
        </template>
        <el-image style="width:100%; height: auto" :src="props.src" fit="cover" >
          <template #error>
            <el-image :src="props.errorSrc" style="width:100%; height:100%" fit="cover" />
          </template>
        </el-image>
      </el-dialog>
  </div>
 
</template>

<script setup lang="ts">
  import { avatarProps } from './props'
  import {reactive } from 'vue'
  import {IOperState} from '../../type/views/code'

  const props = defineProps(avatarProps)
  const emits = defineEmits(['getList', 'handleSort', 'update:page-num', 'update:page-size','handleSelectionChange'])

  const operState = reactive<IOperState>({
    show: false,
    width:'450px',
    title:'',
    showFooter:false,
    loading:false,
  })

  const errorHandler  = (s) =>{

  }

  // 查看大图
  const lookImg = ()=>{
    operState.show = true
  }

</script>


