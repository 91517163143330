import { batchInvAccountOfflineReq, getInvAccountLogReq, GetInvDetailInfoResp, invAccountDeleteReq, openOrCloseLiveLinkReq, resetInvAccountWorkOrderReq } from "../types/code";
import axios, { Response } from "/@/utils/request";

const api = {
  getCodeList: "/base/comm/invitationByPage",
  getShareInfo: "/Invitation/shareSet",
  addCode: "/base/comm/createInvitation",
  updateCode: "/base/comm/updateInvitation",
  delCode: "/base/comm/deleteInvitation",
  getCodeDetailList: "/base/comm/getLoginAccountList",
  getFriendsDetail: "/base/comm/getSocialAccountFansList",
  createLink: "/base/comm/createLiveLink",
  getLiveLink: "/base/comm/liveLinkList",
  getChatTypeAccountCount: "/base/comm/getChatTypeAccountCount",
  clearLiveLinkPVAndIP: "/base/comm/clearLiveLink",
  deleteLiveLink: "/base/comm/deleteLiveLink",
  getInvCount: "/base/comm/getInvCount",
  getInvSetting: "/base/comm/getInvSetting",
  getThirdParty: "/base/comm/getThirdParty",
  resetInvWorkOrder: "/base/comm/resetInvWorkOrder",
  getInvDetailInfo: "/base/comm/getInvDetailInfo",
  openOrCloseLiveLink: "/base/comm/openOrCloseLiveLink",
  resetInvAccountWorkOrder: "/base/comm/resetInvAccountWorkOrder",
  invAccountDelete: "/base/comm/invAccountDelete",
  getInvAccountLog: "/base/comm/getInvAccountLog",
  batchInvAccountOffline: "/base/comm/batchInvAccountOffline",
  invVerifyPassword: "/base/open/invVerifyPassword",
};

const getCodeList = (params: any) => {
  return axios({
    url: api.getCodeList,
    method: "get",
    params,
  });
};
const getShareInfo = (params: any) => {
  return axios({
    url: api.getShareInfo,
    method: "post",
    data: params,
  });
};
const addCode = (params: any) => {
  return axios({
    url: api.addCode,
    method: "post",
    data: params,
  });
};
const updateCode = (params: any) => {
  return axios({
    url: api.updateCode,
    method: "post",
    data: params,
  });
};
const delCode = (params: any) => {
  return axios({
    url: api.delCode,
    method: "post",
    data: params,
  });
};
const getCodeDetailList = (params: any) => {
  return axios({
    url: api.getCodeDetailList,
    method: "get",
    params,
  });
};
const getFriendsDetail = (data: any) => {
  return axios({
    url: api.getFriendsDetail,
    method: "post",
    data,
  });
};
const createLink = (data: any) => {
  return axios({
    url: api.createLink,
    method: "post",
    data,
  });
};
const getLiveLink = (data: any) => {
  return axios({
    url: api.getLiveLink,
    method: "post",
    data,
  });
};
const getChatTypeAccountCount = (data: any) => {
  return axios({
    url: api.getChatTypeAccountCount,
    method: "post",
    data,
  });
};
const clearLiveLinkPVAndIP = (data: any) => {
  return axios({
    url: api.clearLiveLinkPVAndIP,
    method: "post",
    data,
  });
};
const deleteLiveLink = (data: any) => {
  return axios({
    url: api.deleteLiveLink,
    method: "post",
    data,
  });
};
const getInvCount = (data: any) => {
  return axios({
    url: api.getInvCount,
    method: "post",
    data,
  });
};

const getInvSetting = (invId: number) => {
  return axios({
    url: api.getInvSetting + `?invId=${invId}`,
    method: "get",
  });
};

const getThirdParty = () => {
  return axios({
    url: api.getThirdParty,
    method: "get",
  });
};

const resetInvWorkOrder = (data: any) => {
  return axios({
    url: api.resetInvWorkOrder,
    method: "post",
    data,
  });
}

const getInvDetailInfo = (data: any) => {
  return axios<Response<GetInvDetailInfoResp>>({
    url: api.getInvDetailInfo,
    method: "post",
    data,
  });
}

const openOrCloseLiveLink = (data: openOrCloseLiveLinkReq) => {
  return axios({
    url: api.openOrCloseLiveLink,
    method: "post",
    data,
  });
}

const resetInvAccountWorkOrder = (data: resetInvAccountWorkOrderReq) => { 
  return axios({
    url: api.resetInvAccountWorkOrder,
    method: "post",
    data
  });
}

const invAccountDelete = (data: invAccountDeleteReq) => { 
  return axios({
    url: api.invAccountDelete,
    method: "post",
    data
  });
}

const getInvAccountLog = (data: getInvAccountLogReq) => {
  return axios({
    url: api.getInvAccountLog,
    method: "post",
    data
  });
}
const batchInvAccountOffline = (data: batchInvAccountOfflineReq) => {
  return axios({
    url: api.batchInvAccountOffline,
    method: "post",
    data
  });
}

const invVerifyPassword = (data: any) => {
  return axios({
    url: api.invVerifyPassword,
    method: "post",
    data
  });
}

export {
  getCodeList,
  getShareInfo,
  addCode,
  updateCode,
  delCode,
  getCodeDetailList,
  getFriendsDetail,
  createLink,
  getLiveLink,
  getChatTypeAccountCount,
  clearLiveLinkPVAndIP,
  deleteLiveLink,
  getInvCount,
  getInvSetting,
  getThirdParty,
  resetInvWorkOrder,
  getInvDetailInfo,
  openOrCloseLiveLink,
  resetInvAccountWorkOrder,
  invAccountDelete,
  getInvAccountLog,
  batchInvAccountOffline,
  invVerifyPassword
};
