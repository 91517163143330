export default {
    fansLinklist1:"Accounts Live Link",
    fansLinklist2:"Refresh the list",
    fansLinklist3:"Create live link",
    fansLinklist4:"Heading",
    fansLinklist5:"User binding",
    fansLinklist6:"Operate",
    fansLinklist7:"Delete",
    fansLinklist8:"Copy link",
    fansLinklist9:"Edit",
    fansLinklist10:"Edit live link",
    fansLinklist11:"Create live link",
    fansLinklist12:"Delete live link",
    fansLinklist13:"After deletion, the associated sub-links issued will automatically become invalid. Are you sure you want to delete this live link?？",
    fansLinklist14:"Successful operation",
    fansLinklist15:"Copied successfully!",
    fansLinklist16:"Name",
    fansLinklist17:"Platform",
    fansLinklist18:"Number of Account Links",
    fansLinklist19:"User Binding",
    fansLinklist20:"Clicks",
    fansLinklist21:"Number of IPs"
}