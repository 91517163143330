import { ILocalStore } from "/@/type/utils/tools";
import { IMenubarList } from "/@/type/store/layout";
import type { DateModelType } from "element-plus";
import line from "/@/assets/img/line.png";
import ws from "/@/assets/img/whatsapp.png";
import telegram from "/@/assets/img/telegram.png";
import zalo from "/@/assets/img/zalo.png";
import telegramK from "/@/assets/img/telegramK.png";

export function isInput(el: HTMLElement): boolean {
  return el.nodeName.toLocaleLowerCase() === "input";
}
export function isTextarea(el: HTMLElement): boolean {
  return el.nodeName.toLocaleLowerCase() === "textarea";
}

/**
 * localStorage设置有效期
 * @param name localStorage设置名称
 * @param data 数据对象
 * @param pExpires 有效期(默认100年)
 */
export function setLocal(
  name: string,
  data: IObject<any>,
  pExpires = 1000 * 60 * 60 * 24 * 365 * 100
): void {
  const d = data as ILocalStore;
  d.startTime = Date.now();
  d.expires = pExpires;
  localStorage.setItem(name, JSON.stringify(data));
}
/**
 * 判断localStorage有效期是否失效
 * @param name localStorage设置名称
 */
export async function useLocal(name: string): Promise<ILocalStore> {
  return new Promise((resolve, reject) => {
    const local = getLocal<ILocalStore>(name);
    if (local.startTime + local.expires < Date.now())
      reject(`${name}已超过有效期`);
    resolve(local);
  });
}
/**
 * 获取localStorage对象并转成对应的类型
 * @param name localStorage设置名称
 */
export function getLocal<T>(name: string): T {
  const l = localStorage.getItem(name);
  const local = JSON.parse(l !== null ? l : "{}") as unknown as T;
  return local;
}

/**
 * 函数节流
 * @param time 间隔时间
 */
export function throttle(time = 500): () => Promise<void> {
  let timer: NodeJS.Timeout | null = null;
  let firstTime = true;
  return () => {
    return new Promise((resolve) => {
      if (firstTime) {
        resolve();
        return (firstTime = false);
      }
      if (timer) return false;
      timer = setTimeout(() => {
        if (timer) clearTimeout(timer);
        timer = null;
        resolve();
      }, time);
    });
  };
}

/**
 * list结构转tree
 * @param data list原始数据
 * @param pid 最外层pid
 */
export function listToTree(
  data: Array<IMenubarList>,
  pid: string | number = 1,
  isChildNull = false
): Array<IMenubarList> {
  const d: Array<IMenubarList> = [];
  data.forEach((val) => {
    if (val.parentId == pid) {
      const list = listToTree(data, val.id, isChildNull);
      const obj: IMenubarList = { ...val };
      if (!isChildNull || list.length !== 0) {
        obj.children = list;
      }
      d.push(obj);
    }
  });
  return d;
}
/**
 * 字符串首字母大写
 * @param str
 * @returns
 */
export function firstUpperCase(str: string): string {
  return str.replace(/^\S/, (s) => s.toUpperCase());
}

/**
 * 两次编码url
 * @param url
 * @returns
 */
export function decode(url: string): string {
  return decodeURIComponent(decodeURIComponent(url));
}

/**
 * 两次解码url
 * @param url
 * @returns
 */
export function encode(url: string): string {
  return encodeURIComponent(encodeURIComponent(url));
}

/**
 * 计算两个日期一共多少天
 * @param fromDate
 * @param toDate
 * @returns
 */
export const computeDiffDay = (
  fromDate: DateModelType,
  toDate: DateModelType
) => {
  const timestampFrom = new Date(fromDate).getTime();
  const timestampTo = new Date(toDate).getTime();
  const diffDay = parseInt(
    ((timestampTo - timestampFrom) / (1000 * 3600 * 24)).toString()
  );
  return diffDay;
};

/**
 * 时间日期转换
 * @param date 当前时间，new Date() 格式
 * @param format 需要转换的时间格式字符串
 * @description format 字符串随意，如 `YYYY-mm、YYYY-mm-dd`
 * @description format 季度："YYYY-mm-dd HH:MM:SS QQQQ"
 * @description format 星期："YYYY-mm-dd HH:MM:SS WWW"
 * @description format 几周："YYYY-mm-dd HH:MM:SS ZZZ"
 * @description format 季度 + 星期 + 几周："YYYY-mm-dd HH:MM:SS WWW QQQQ ZZZ"
 * @returns 返回拼接后的时间字符串
 */
export function formatDate(date: Date, format: string): string {
  let we = date.getDay(); // 星期
  let z = getWeek(date); // 周
  let qut = Math.floor((date.getMonth() + 3) / 3).toString(); // 季度
  const opt: { [key: string]: string } = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月(月份从0开始，要+1)
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    "q+": qut, // 季度
  };
  // 中文数字 (星期)
  const week: { [key: string]: string } = {
    "0": "日",
    "1": "一",
    "2": "二",
    "3": "三",
    "4": "四",
    "5": "五",
    "6": "六",
  };
  // 中文数字（季度）
  const quarter: { [key: string]: string } = {
    "1": "一",
    "2": "二",
    "3": "三",
    "4": "四",
  };
  if (/(W+)/.test(format))
    format = format.replace(
      RegExp.$1,
      RegExp.$1.length > 1
        ? RegExp.$1.length > 2
          ? "星期" + week[we]
          : "周" + week[we]
        : week[we]
    );
  if (/(Q+)/.test(format))
    format = format.replace(
      RegExp.$1,
      RegExp.$1.length == 4 ? "第" + quarter[qut] + "季度" : quarter[qut]
    );
  if (/(Z+)/.test(format))
    format = format.replace(
      RegExp.$1,
      RegExp.$1.length == 3 ? "第" + z + "周" : z + ""
    );
  for (let k in opt) {
    let r = new RegExp("(" + k + ")").exec(format);
    // 若输入的长度不为1，则前面补零
    if (r)
      format = format.replace(
        r[1],
        RegExp.$1.length == 1 ? opt[k] : opt[k].padStart(RegExp.$1.length, "0")
      );
  }
  return format;
}
/**
 * 获取当前日期是第几周
 * @param dateTime 当前传入的日期值
 * @returns 返回第几周数字值
 */
export function getWeek(dateTime: Date): number {
  let temptTime = new Date(dateTime.getTime());
  // 周几
  let weekday = temptTime.getDay() || 7;
  // 周1+5天=周六
  temptTime.setDate(temptTime.getDate() - weekday + 1 + 5);
  let firstDay = new Date(temptTime.getFullYear(), 0, 1);
  let dayOfWeek = firstDay.getDay();
  let spendDay = 1;
  if (dayOfWeek != 0) spendDay = 7 - dayOfWeek + 1;
  firstDay = new Date(temptTime.getFullYear(), 0, 1 + spendDay);
  let d = Math.ceil((temptTime.valueOf() - firstDay.valueOf()) / 86400000);
  let result = Math.ceil(d / 7);
  return result;
}

/**
 * 密码 (以字母开头，长度在6~16之间，只能包含字母、数字和下划线)
 * @param val 当前值字符串
 * @returns 返回 true: 密码正确
 */
export function verifyPassword(val: string) {
  // false: 密码不正确
  if (!/^[a-zA-Z]\w{5,15}$/.test(val)) return false;
  // true: 密码正确
  else return true;
}

/**
 * 密码 (长度在4~8之间，只能包含字母、数字)
 * @param val 当前值字符串
 * @returns 返回 true: 密码正确
 */
export function verifyPassword_(val: string) {
  // false: 密码不正确
  if (!/^[A-Za-z0-9].{3,7}$/.test(val)) return false;
  // true: 密码正确
  else return true;
}
/**
 * 去掉中文及空格
 * @param val 当前值字符串
 * @returns 返回处理后的字符串
 */
export function verifyCnAndSpace(val: string) {
  // 匹配中文与空格
  let v = val.replace(/[\u4e00-\u9fa5\s]+/g, "");
  // 匹配空格
  v = v.replace(/(^\s*)|(\s*$)/g, "");
  // 返回结果
  return v;
}
export function getQueryString(name) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}

export function downloadBinaryData(binaryData, strName = "") {
  var blob = new Blob([binaryData], { type: "application/force-download" });
  var url = URL.createObjectURL(blob);

  var link = document.createElement("a");
  link.href = url;
  if (strName) {
    link.download = strName + ".xlsx";
  } else {
    link.download = "accunt-" + Date.parse(new Date()) + ".xlsx";
  }

  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, 100);
}

export const getChatAppAvater = (socialAppType: string) => {
  return (
    {
      line: line,
      whatsapp: ws,
      telegram: telegram,
      zalo: zalo,
      telegramK: telegramK,
    }[socialAppType] ?? ""
  );
};

// js 对象深拷贝的函数
export function deepClone(obj: any) {
  if (obj === null) return null;
  if (typeof obj !== "object") return obj;
  if (obj instanceof Date) return new Date(obj);
  if (obj instanceof RegExp) return new RegExp(obj);
  let newObj = new obj.constructor(); //保持继承的原型
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = deepClone(obj[key]);
    }
  }
  return newObj;
}


export function formatToTwoDecimalPlaces(num: number): number {
  const strNum = num.toString();
  const decimalIndex = strNum.indexOf('.');
  
  if (decimalIndex === -1) {
    return parseFloat(strNum + ".00"); // 如果没有小数点，直接返回两位小数
  }
  
  return parseFloat(strNum.slice(0, decimalIndex + 3)); // 截取到小数点后两位
}

export function exeDownloadFile (response, fileName: string = '') {
  // 创建 Blob 对象
  let blob = new Blob([response.data], {type: response.headers['content-type']})
  // 获取文件名
  if (fileName == '') fileName = response.headers['content-disposition'].match(/filename=(.*)/)[1]
  // 创建指向 Blob 对象地址的URL
  let href = window.URL.createObjectURL(blob)
  // 创建用于跳转至下载链接的 a 标签
  let downloadElement = document.createElement('a')
  // 属性配置
  downloadElement.style.display = 'none'
  downloadElement.href = href
  downloadElement.download = fileName
  // 将 a 标签挂载至当前页面
  document.body.appendChild(downloadElement)
  // 触发下载事件
  downloadElement.click()
  // 移除已挂载的 a 标签
  document.body.removeChild(downloadElement)
  // 释放 Blob URL
  window.URL.revokeObjectURL(href)
}
