import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { IMenubarList } from '/@/type/store/layout'
import { components } from '/@/router/asyncRouter'

const Components: IObject<() => Promise<typeof import('*.vue')>> = Object.assign({}, components, {
  Layout: (() => import('/@/layout/index.vue')) as unknown as () => Promise<typeof import('*.vue')>,
  Redirect: (() => import('/@/layout/redirect.vue')) as unknown as () => Promise<typeof import('*.vue')>
})


// 静态路由页面
export const allowRouter: Array<IMenubarList> = [
  {
    name: 'Code',
    path: '/',
    component: Components['Layout'],
    redirect: '/Code/List',
    meta: {
      title: '邀请码管理',
      icon: 'm-code',
      activeIcon: 'm-code-active',
      langKey: 'menu1',
    },
    children: [
      {
        name: 'List',
        path: '/Code/List',
        component: Components['List'],
        meta: {
          title: '邀请码管理',
          icon: 'm-code',
          activeIcon: 'm-code-active',
          langKey: 'menu1',
        },
      },
      {
        name: 'Detail',
        path: '/Code/Detail',
        component: Components['Detail'],
        meta: {
          title: '邀请码详情',
          icon: 'm-code',
          activeIcon: 'm-code-active',
          hidden: true,
          activeMenu: '/Code/List',
          langKey: 'menu1',
        },
      },
      {
        name: 'friends_detail',
        path: '/Code/friends_detail',
        component: Components['friends_detail'],
        meta: {
          title: '邀请码详情',
          icon: 'm-code',
          activeIcon: 'm-code-active',
          hidden: true,
          activeMenu: '/Code/List',
          langKey: 'menu1',
        },
      },
    ],
  },
  // {
  //   name: "workOrder",
  //   path: "/workOrder",
  //   component: Components["Layout"],
  //   redirect: "/workOrder/wList",
  //   meta: {
  //     title: "工单管理",
  //     icon: "m-workOrder",
  //     activeIcon: "m-workOrder-active",
  //     langKey:"menu2"
  //   },
  //   children: [
  //     {
  //       name: "wList",
  //       path: "/workOrder/wList",
  //       component: Components["wList"],
  //       meta: {
  //         title: "工单管理",
  //         icon: "m-workOrder",
  //         activeIcon: "m-workOrder-active",
  //         langKey:"menu2"
  //       },
  //     },
  //     {
  //       name: "wDetail",
  //       path: "/workOrder/wDetail",
  //       component: Components["wDetail"],
  //       meta: {
  //         title: "工单管理",
  //         icon: "m-workOrder",
  //         activeIcon: "m-workOrder-active",
  //         hidden: true,
  //         activeMenu: "/workOrder/wList",
  //         langKey:"menu2"
  //       },
  //     },
  //     {
  //       name: "orderFansDetails",
  //       path: "/workOrder/orderFansDetails",
  //       component: Components["orderFansDetails"],
  //       meta: {
  //         title: "工单管理",
  //         icon: "m-workOrder",
  //         activeIcon: "m-workOrder-active",
  //         hidden: true,
  //         activeMenu: "/workOrder/wList",
  //         langKey:"menu2"
  //       },
  //     },
  //     {
  //       name: "workOrderAccountLog",
  //       path: "/workOrder/workOrderAccountLog",
  //       component: Components["workOrderAccountLog"],
  //       meta: {
  //         title: "工单管理",
  //         icon: "m-workOrder",
  //         activeIcon: "m-workOrder-active",
  //         hidden: true,
  //         activeMenu: "/workOrder/wList",
  //         langKey:"menu2"
  //       },
  //     },
  //   ],
  // },
  {
    name: 'fansLink',
    path: '/fansLink',
    component: Components['Layout'],
    redirect: '/fansLink/list',
    meta: {
      title: '粉丝活链',
      icon: 'm-fansLink',
      activeIcon: 'm-fansLink-active',
      langKey: 'menu3',
    },
    children: [
      {
        name: 'fList',
        path: '/fansLink/list',
        component: Components['list'],
        meta: {
          title: '粉丝活链',
          icon: 'm-fansLink',
          activeIcon: 'm-fansLink-active',
          langKey: 'menu3',
        },
      },
    ],
  },
  {
    name: 'fansLabel',
    path: '/fansLabel',
    component: Components['Layout'],
    redirect: '/fansLabel/list',
    meta: {
      title: '粉丝标签',
      icon: 'm-fansLabel',
      activeIcon: 'm-fansLabel-active',
      langKey: 'menu4',
    },
    children: [
      {
        name: 'fansLabelList',
        path: '/fansLabel/list',
        component: Components['f_list'],
        meta: {
          title: '粉丝标签',
          icon: 'm-fansLabel',
          activeIcon: 'm-fansLabel-active',
          langKey: 'menu4',
        },
      },
    ],
  },
  {
    name: 'fansData',
    path: '/fansData',
    component: Components['Layout'],
    redirect: '/fansData/list',
    meta: {
      title: '粉丝数据',
      icon: 'm-fansData',
      activeIcon: 'm-fansData-active',
      langKey: 'menu5',
    },
    children: [
      {
        name: 'fansDataList',
        path: '/fansData/list',
        component: Components['fans_data_list'],
        meta: {
          title: '粉丝数据',
          icon: 'm-fansData',
          activeIcon: 'm-fansData-active',
          langKey: 'menu5',
        },
      },
    ],
  },
  {
    name: 'comboOrder',
    path: '/comboOrder',
    component: Components['Layout'],
    redirect: '/comboOrder/list',
    meta: {
      title: '购买记录',
      icon: 'm-comboOrder',
      activeIcon: 'm-comboOrder-active',
      langKey: 'menu6',
      channelId: [1],
    },
    children: [
      {
        name: 'comboOrderList',
        path: '/comboOrder/list',
        component: Components['order'],
        meta: {
          title: '购买记录',
          icon: 'm-comboOrder',
          activeIcon: 'm-comboOrder-active',
          langKey: 'menu6',
        },
      },
    ],
  },
  {
    name: 'statistics',
    path: '/',
    component: Components['Layout'],
    redirect: '/promo/statistics',
    meta: {
      title: '推广返佣',
      icon: 'm-promo_list',
      activeIcon: 'm-promo_list-active',
      langKey: 'menu11',
      hotIcon: 'promo',
      channelId: [1],
    },
    children: [
      {
        name: 'promo',
        path: '/promo/statistics',
        component: Components['statistics'],
        meta: {
          title: '推广明细',
          langKey: 'menu14',
        },
      },
      {
        name: 'promo_list',
        path: '/promo/list',
        component: Components['promo_list'],
        meta: {
          title: '收益统计',
          langKey: 'menu12',
        },
      },
    ],
  },
  {
    name: 'EmptyTemplate',
    path: '/EmptyTemplate',
    component: Components['Layout'],
    redirect: '/EmptyTemplate',
    meta: { title: '', icon: '', hidden: true },
    children: [
      {
        name: 'EmptyTemplate',
        path: '/EmptyTemplate',
        component: Components['EmptyTemplate'],
        meta: { title: '空模版', icon: 'm-code', activeIcon: 'm-code-active' },
      },
    ],
  },
  {
    name: 'RedirectPage',
    path: '/redirect',
    component: Components['Layout'],
    meta: { title: '重定向页面', hidden: true },
    children: [
      {
        name: 'Redirect',
        path: '/redirect/:pathMatch(.*)*',
        meta: {
          title: '重定向页面',
          icon: '',
        },
        component: Components.Redirect,
      },
    ],
  },
  {
    name: 'Login',
    path: '/Login',
    component: Components.Login,
    meta: { title: '登录', hidden: true },
  },
  {
    name: 'Register',
    path: '/Register',
    component: Components.Register,
    meta: { title: '注册', hidden: true },
  },
  {
    name: 'Reset',
    path: '/Reset',
    component: Components.Reset,
    meta: { title: '忘记密码', hidden: true },
  },
  {
    name: 'w_verify',
    path: '/fansMerchant/verify',
    component: Components['verify'],
    meta: { title: '工单', hidden: true },
  },
  {
    name: 'w_detail',
    path: '/fansMerchant/w_detail',
    component: Components['w_detail'],
    meta: { title: '工单', hidden: true },
  },
  {
    name: 'w_fans_detail',
    path: '/fansMerchant/w_fans_detail',
    component: Components['w_fans_detail'],
    meta: { title: '工单粉丝详情', hidden: true },
  },
];
const router = createRouter({
    history: createWebHashHistory(), // createWebHistory
    routes: allowRouter as RouteRecordRaw[]
})

export default router
