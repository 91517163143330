<template>
    <el-dropdown @command="changeLang">
        <span class="dropDown">
          <el-icon><MapLocation /></el-icon>
            <span class="title">{{t(locale)}}</span>
        </span>
        <template #dropdown>
            <el-dropdown-menu class="menu">
                <el-dropdown-item v-for="item in locales" :key="item" :command="item" :class="{'active': locale === item}">
                    {{t(item)}}
                </el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>
<script lang="ts">
import { defineComponent, WritableComputedRef } from "vue";
import { setI18nLanguage } from '/@/config/i18n';
import { useI18n } from "vue-i18n";
import { MapLocation } from '@element-plus/icons-vue'
import router from '/@/router'
import { isString } from '/@/utils/is'
interface SelectLangSetupData {
    t: (key: string | number) => string;
    locales: string[];
    languageLabels: {[key: string]: string};
    changeLang: ({ key }: any) => void;
    locale: WritableComputedRef<string>;
}

export default defineComponent({
    name: 'SelectLang',
    components: { MapLocation },
    setup(): SelectLangSetupData {

        const { locale, t }  = useI18n();

        const locales: string[] = ['zh-CN', 'en-US'];
        const languageLabels: {[key: string]: string} = {
            'zh-CN': '简体中文',
            'en-US': 'English',
        };

        // 切换语言
        const changeLang = (command: string): void => {
          setI18nLanguage(command,false);
          const currentRoute = router.currentRoute.value;
          const appTitle = import.meta.env.VITE_APP_TITLE
          const reg = new RegExp(/^(.+)(\s\|\s.+)$/)
          document.title = !currentRoute.meta.title
              ? appTitle
              : appTitle.match(reg)
                  ? appTitle.replace(reg, `${currentRoute.meta.title}$2`)
                  : isString(currentRoute.meta.langKey)?(t(currentRoute.meta.langKey)) +'|'+ t(appTitle):t(appTitle)
        };
        return {
            locales,
            languageLabels,
            changeLang,
            locale,
            t
        }
    }
})
</script>
<style lang="postcss" scoped>
.dropDown {
  cursor: pointer;
  color: #262E44;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .title{
    font-weight: 400;
  }
}
.menu {
    .el-dropdown-menu__item {
        min-width: 100px;
    }
}
.active {
    background-color: var(--el-dropdown-menuItem-hover-fill);
    color: var(--el-dropdown-menuItem-hover-color);

}
</style>
