import UsualDialog from './UsualDialog/index.vue'
import ConTop from './ConTop/index.vue'
import UsualTable from './UsualTable/index.vue'
import ComAvatar from './ComAvatar/index.vue'
import SelectLang from './SelectLang/index.vue'

interface IState {
  UsualDialog: String,
  ConTop:String,
  UsualTable: String,
  ComAvatar:String,
  SelectLang:String
}

const components: { [IState: string]: any } = {
  UsualDialog,
  ConTop,
  UsualTable,
  ComAvatar,
  SelectLang
}

export default {
  install: (app: { component: (key: string, components: any) => void }) => {
    for (const key in components) {
      app.component(key, components[key])
    }
  }
}
