export default {
    workOrderDetail1:"工单管理",
    workOrderDetail2:"工单详情",
    workOrderDetail3:"账号",
    workOrderDetail4:"归属平台",
    workOrderDetail5:"分配状态",
    workOrderDetail6:"在线状态",
    workOrderDetail7:"账号头像",
    workOrderDetail8:"昵称",
    workOrderDetail9:"当日入粉/当日上限",
    workOrderDetail10:"已完成/总目标",
    workOrderDetail11:"当日重粉数",
    workOrderDetail12:"重粉总数",
    workOrderDetail13:"账号链接地址",
    workOrderDetail14:"账号链接地址",
    workOrderDetail15:"入粉详情",
    workOrderDetail16:"名称",
    workOrderDetail17:"复制活链",
    workOrderDetail18:"账号链接数量",
    workOrderDetail19:"用户绑定",
    workOrderDetail20:"点击数",
    workOrderDetail21:"IP数"
}