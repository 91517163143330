export default {
    reset1:"前往官网",
    reset2:"请输入电子邮箱账号",
    reset3:"邮箱验证码",
    reset4:"获取验证码",
    reset5:"请输入密码",
    reset6:"确认密码",
    reset7:"修改",
    reset8:"取消",
    reset9:"温馨提示",
    reset10:"请输入邮箱",
    reset11:"请输入正确邮箱格式",
    reset12:"请输入验证码",
    reset13:"请输入密码",
    reset14:"发送成功",
    reset15:"密码由6-16位的数字字母组成",
    reset16:"两次密码不一致",
    reset17:"修改",
    reset18:"修改成功",
    reset19:"忘记密码",
}