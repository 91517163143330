export default {
  promoDetail1: '交易详情',
  promoDetail2: '支付时间',
  promoDetail3: '支付类型',
  promoDetail4: '支付金额(U)',
  promoDetail5: '您的佣金(U)',
  promoDetail6: '佣金状态',
  promoDetail7: '打款中',
  promoDetail8: '已到账',
  promoDetail9: '已撤回',
  promoDetail10: '首次交易',
  promoDetail11: '续费',
  promoDetail12: '交易详情',
};
