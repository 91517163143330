export default {
    fansLinklist1:"粉丝活链",
    fansLinklist2:"刷新列表",
    fansLinklist3:"创建活链",
    fansLinklist4:"名称",
    fansLinklist5:"用户绑定",
    fansLinklist6:"操作",
    fansLinklist7:"删除",
    fansLinklist8:"复制活链",
    fansLinklist9:"编辑",
    fansLinklist10:"编辑活链",
    fansLinklist11:"创建活链",
    fansLinklist12:"删除活链",
    fansLinklist13:"删除后，将会对发放出去的关联子链接自动失效。确定删除此条活链吗？",
    fansLinklist14:"操作成功",
    fansLinklist15:"复制成功!",
    fansLinklist16:"名称",
    fansLinklist17:"平台",
    fansLinklist18:"账号链接数量",
    fansLinklist19:"用户绑定",
    fansLinklist20:"点击数",
    fansLinklist21:"IP数"
}