export default {
    "codeShare":{
        components1:"地址",
        components2:"密码",
        components3:"邀请码",
        components4:"复制成功",
        components5:"一键复制",
    },
    "linkDetail":{
        components1:"活链名称：",
        components2:"例如：XXX推广（未填写，默认为日期时间）",
        components3:"当前邀请码勾选的号码",
        components4:"账号数量：",
        components5:"用户绑定：",
        components6:"开启",
        components7:"关闭",
        components8:"关闭后，同一个设备每次访问都将重新分配一个新的号码，有可能一个用户添加多个号码",
        components9:"开启后，在符合条件的号码中，以顺序循环的方法进行切换。同一个设备每次访问连接同一个号码，防止同一个客户添加多个号码",
        components10:"绑定方式：",
        components11:"IP绑定",
        components12:"创建成功",
        components13:"数据有误",
        components14:"个"
    },
    "linkList":{
        components1:"名称",
        components2:"复制活链",
        components3:"用户绑定",
        components4:"操作",
        components5:"清除",
        components6:"删除",
        components7:"活链复制成功!",
        components8:"数据有误",
        components9:"清除成功",
        components10:"删除成功",
        components11:"确定要清除吗？",
        components12:"确定要删除吗？"
    },
    "orderFansDetails":{
        components1:"时间：",
        components2:"结束日期",
        components3:"开始日期",
        components4:"粉丝账号：",
        components5:"粉丝账号搜索：",
        components6:"粉丝昵称：",
        components7:"粉丝昵称搜索",
        components8:"查找",
        components9:"重置",
        components10:"导出数据",
        components11:"头像",
        components12:"粉丝类型",
        components13:"新粉",
        components14:"重粉",
        components15:"底粉",
        components16:"全部数据",
        components17:"当日数据",
        components18:"没有可导出的数据"
    },
    "workOrderAccountLog":{
        components1:"实时日志记录",
        components2:"当前{num}条记录",
        components3:"最多显示近100条记录",
    },
    "workOrderDetail":{
        components1:"工单号：",
        components2:"每日入粉重置时间：",
        components3:"工单状态：",
        components4:"开启",
        components5:"完成",
        components6:"关闭",
        components7:"工单重置数据时间：",
        components8:"备注：",
        components9:"邀请码：",
        components10:"活链链接：",
        components11:"点击复制",
        components12:"下载客户端",
        components13:"账户ID",
        components14:"账户昵称",
        components15:"请输入",
        components16:"查找",
        components17:"重置",
        components18:"开启分配",
        components19:"关闭分配",
        components20:"批量重置",
        components21:"工单重置后进粉总数 (已去重)：",
        components22:"当日置零后进粉总数 (已去重)：",
        components23:"账号",
        components24:"头像",
        components25:"分配状态",
        components26:"请设置账号Id",
        components27:"在线",
        components28:"离线",
        components29:"当日入粉/当日上限",
        components30:"账号链接地址",
        components31:"日志",
        components32:"入粉详情",
        components33:"查看",
        components34:"工单已",
        components35:"完成",
        components36:"关闭",
        components37:"不可操作",
        components38:"请选择生成活链账号",
        components39:"生成活链",
        components40:"活链管理",
        components41:"请选择账号",
        components42:"分配任务",
        components43:"确认要开启分配吗？",
        components44:"确认要关闭分配吗？",
        components45:"批量重置",
        components46:"确认重置",
        components47:"操作成功",
        components48:"先关闭账号分配后再进行删除",
        components49:"批量删除",
        components50:"确认要删除吗？",
        components51:"没有符合条件的账号，请重新选择",
        components52:"个",
        components53:"在线状态",
        components54:"复制成功!",
    }
}