export default {
    fansLinkComponents1:"活链名称：",
    fansLinkComponents2:"例如：XXX推广（未填写，默认为日期时间）",
    fansLinkComponents3:"归属平台：",
    fansLinkComponents4:"请选择",
    fansLinkComponents5:"用户绑定：",
    fansLinkComponents6:"开启",
    fansLinkComponents7:"关闭",
    fansLinkComponents8:"关闭后，同一个设备每次访问都将重新分配一个新的号码，有可能一个用户添加多个号码",
    fansLinkComponents9:"开启后，在符合条件的号码中，以顺序循环的方法进行切换。同一个设备每次访问连接同一个号码，防止同一个客户添加多个号码",
    fansLinkComponents10:"绑定方式：",
    fansLinkComponents11:"IP绑定",
    fansLinkComponents12:"上传文件：",
    fansLinkComponents13:"点击上传",
    fansLinkComponents14:"每个账号为1行，多个账号请用回车换行",
    fansLinkComponents15:"最多可输",
    fansLinkComponents16:"个账号",
    fansLinkComponents17:"账号（已去重）",
    fansLinkComponents18:"请选择平台",
    fansLinkComponents19:"请点击上传文件或输入账号",
    fansLinkComponents20:"创建成功",
    fansLinkComponents21:"编辑成功",
    fansLinkComponents22:"只能上传txt文件",
    fansLinkComponents23:"文件内容为空",
    fansLinkComponents24:"账号总数不得超过",
    fansLinkComponents25:"个",
    fansLinkComponents26:"共计："
}