export default {
  statistics1: 'Promotion data',
  statistics2: 'Commission accumulation',
  statistics3: 'Link clicks',
  statistics4: 'Number of registered users',
  statistics5: 'Exclusive promotion link',
  statistics6:
    'After recommending friends to register and purchase through this link, the commission will be credited immediately.',
  statistics7: 'Your exclusive promotional link',
  statistics8: 'Copy',
  statistics9:
    "1.First payment: If the recommended friend registers through your exclusive promotion link and pays for the first time, you will receive {firstRecharge} of the friend's payment as a promotion commission.",
  statistics10:
    '2.Friend renewal: Recommended friends register and purchase through your exclusive promotion link. For each subsequent renewal, you will receive {afterRecharge} of the transaction amount as a promotion commission, and the commission payment cycle is one year. That is, if your friends continue to renew their subscriptions, you will continue to receive commission income.',
  statistics11: '3.Commission description',
  statistics12:
    '•Each time a recommended friend completes a purchase, the promotion commission will be immediately distributed to the account balance, and you can withdraw it at any time.',
  statistics13: '•The commission payment cycle is 1 year. That is, if your friends continue to renew their subscriptions, you will continue to receive commission income.',
  statistics14: '•There is no upper limit on the amount of commission. With continuous promotion, the amount will continue to accumulate.',
  statistics15: 'Promote politely',
  statistics16: 'Copied successfully',
  statistics17: 'Special instructions',
  userBalance: 'Account balance',
};
