export default {
    login1:"Go to official website",
    login2:"Please enter email account",
    login3:"Please enter password",
    login4:"User Agreement",
    login5:"Privacy agreement",
    login6:"Contact Customer Service",
    login7:"Forget the password",
    login8:"Register an account",
    login9:"Click to contact customer service",
    login10:"Please input your email",
    login11:"Please enter the correct email format",
    login12:"Log in",
    login13:"Please read and agree to the User Agreement and Privacy Policy",
    login14:"Welcome",
    login15:"Welcome back",
    login16:"I have read and agree",
    login17:"%channelName% Translator/Counter Management Backend",
    login18:"Other login",
    login19:"Google account login",
    login20:"Telegram account login",
    otherLoginErr: "Login failed, please try again later or use another login method",
}
