export default {
  firstLogin1: '欢迎选择%channelName%翻译器SCRM系统！',
  firstLogin2:
    '我们将助力您链接全球用户，挖掘全球市场的无限潜力，实现业务飞跃。',
  firstLogin3: '如何使用',
  firstLogin4: '1、点击邀请码管理：',
  firstLogin5: '2、创建邀请码：',
  firstLogin6: '点击页面右上角“创建邀请码”按钮；',
  firstLogin7: '3、完成邀请码创建：',
  firstLogin8: '需填写邀请码相关参数，完成创建；',
  firstLogin9: '4、使用客户端：',
  firstLogin10:'下载%channelName%客户端，填入邀请码，开始体验%channelName%产品功能。',
  firstLogin11: '限时促销，先到先得',
  firstLogin12: '点击后台首页的“邀请码管理”模块；',
};
