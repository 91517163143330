export default {
    fansLabelList1:"粉丝标签",
    fansLabelList2:"标签名称：",
    fansLabelList3:"请输入内容",
    fansLabelList4:"查找",
    fansLabelList5:"重置",
    fansLabelList6:"创建标签",
    fansLabelList7:"批量删除",
    fansLabelList8:"操作",
    fansLabelList9:"编辑",
    fansLabelList10:"删除",
    fansLabelList11:"编辑标签",
    fansLabelList12:"创建标签",
    fansLabelList13:"请选择要删除的标签",
    fansLabelList14:"删除标签",
    fansLabelList15:"确定要删除吗？",
    fansLabelList16:"操作成功",
    fansLabelList17:"勾选栏",
    fansLabelList18:"标签名称",
    fansLabelList19:"使用量",
    fansLabelList20:"最后编辑时间"
}