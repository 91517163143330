export default {
    workOrderList1:"工单管理",
    workOrderList2:"邀请码",
    workOrderList3:"请输入邀请码",
    workOrderList4:"备注",
    workOrderList5:"请输入备注",
    workOrderList6:"工单号",
    workOrderList7:"请输入",
    workOrderList8:"查找",
    workOrderList9:"重置",
    workOrderList10:"工单编号",
    workOrderList11:"开启",
    workOrderList12:"关闭",
    workOrderList13:"操作",
    workOrderList14:"工单详情",
    workOrderList15:"分享工单",
    workOrderList16:"编辑工单",
    workOrderList17:"重置工单数据",
    workOrderList18:"关闭",
    workOrderList19:"删除",
    workOrderList20:"删除任务",
    workOrderList21:"确认要删除吗？",
    workOrderList22:"操作成功",
    workOrderList23:"工单链接复制成功",
    workOrderList24:"分享链接",
    workOrderList25:"编辑工单",
    workOrderList26:"重置",
    workOrderList27:"确认要重置吗？",
    workOrderList28:"工单编号",
    workOrderList29:"工单名称",
    workOrderList30:"工单备注",
    workOrderList31:"对应邀请码",
    workOrderList32:"工单密码",
    workOrderList33:"创建时间",
    workOrderList34:"工单状态",
    workOrderList35:"已完成/总目标（已去重）",
    workOrderList36:"重粉总数",
    workOrderList37:"当日进粉(已去重)",
    workOrderList38:"状态",
    workOrderList39:"关闭工单",
    workOrderList40:"是否关闭工单，关闭后，该分享的工单页将无法登录，您的工单内账号分流将会失效！"
}