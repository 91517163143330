export default {
    workOrderFansDetail1:"Fan Count Ticket",
    workOrderFansDetail2:"Ticket Details",
    workOrderFansDetail3:"Fan count ticket fan details",
    workOrderFansDetail4:"Fan account",
    workOrderFansDetail5:"Fan nickname",
    workOrderFansDetail6:"Fan avatar",
    workOrderFansDetail7:"Add time",
    workOrderFansDetail8:"Fan type",
    workOrderFansDetail9:"All",
    workOrderFansDetail10:"New fan",
    workOrderFansDetail11:"New Fans",
    workOrderFansDetail12:"Existing Fans",
    workOrderFansDetail13:"Export today's data",
    workOrderFansDetail14:"Export all data"
}