export const avatarProps = {

  size:{
    type:Number,
    default:42
  },
  shape:{
    type: String,
    default:'square'
  },
  src:{
    type:String,
    default:''
  },
  errorSrc:{
    type:String,
    default:''
  }
}
