export default {
  menu1: '邀请码管理',
  menu2: '工单管理',
  menu3: '粉丝活链',
  menu4: '粉丝标签',
  menu5: '粉丝数据',
  menu6: '购买记录',
  menu7: '话术回复',
  menu8: '话术素材库',
  menu9: '关键词回复',
  menu10: '欢迎语回复',
  menu11: '推广返佣',
  menu12: '收益统计',
  menu13: '高额返利',
  menu14: '推广明细',
};
