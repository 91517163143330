export const dialogProps = {
  show: Boolean,
  title: String,
  loading: Boolean,
  clickModal: {
    type: Boolean,
    default: true,
  },
  clickEsc: {
    type: Boolean,
    default: true,
  },
  closeFun: {
    type: Function,
    default: () => {},
  },
  width: {
    type: String,
    default: '34%',
  },
  show_footer: {
    type: Boolean,
    default: true,
  },
  show_close: {
    type: Boolean,
    default: true,
  },
};
