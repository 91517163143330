export default {
    "codeShare":{
        components1:"Share Ticket Link",
        components2:"Password",
        components3:"Invitation Code",
        components4:"Copied Successfully",
        components5:"One-Click Copy",
    },
    "linkDetail":{
        components1:"Active Link Name:",
        components2:"For example: XXX promotion (not filled in, default is date and time)",
        components3:"The number selected by the current invitation code",
        components4:"Number of accounts：",
        components5:"User binding：",
        components6:"Turn on",
        components7:"Close",
        components8:"After closing, the same device will be reassigned a new number every time it is accessed, and it is possible for one user to add multiple numbers.",
        components9:"After it is turned on, the numbers that meet the conditions are switched in a sequential cycle. The same device connects to the same number every time to prevent the same customer from adding multiple numbers.",
        components10:"Binding method：",
        components11:"IP binding",
        components12:"Created successfully",
        components13:"Data is wrong",
        components14:"Individual"
    },
    "linkList":{
        components1:"Name",
        components2:"Copy live chain",
        components3:"User binding",
        components4:"Operate",
        components5:"Remove",
        components6:"Delete",
        components7:"Live chain copy successful!",
        components8:"Data is wrong",
        components9:"Clear successfully",
        components10:"Successfully deleted",
        components11:"Are you sure you want to clear it?？",
        components12:"You sure you want to delete it？"
    },
    "orderFansDetails":{
        components1:"Time：",
        components2:"End date",
        components3:"Start date",
        components4:"Fan account：",
        components5:"Fan account search：",
        components6:"Fan nickname：",
        components7:"Fan nickname search",
        components8:"Search",
        components9:"Reset",
        components10:"Export data",
        components11:"Avatar",
        components12:"Fan type",
        components13:"New fan",
        components14:"New Fans",
        components15:"Existing Fans",
        components16:"All data",
        components17:"Today's data",
        components18:"No data to export"
    },
    "workOrderAccountLog":{
        components1:"Real-time logging",
        components2:"Current {num} records",
        components3:"Display up to nearly 100 records",
    },
    "workOrderDetail":{
        components1:"Fan count ticket Number：",
        components2:"Daily Fan Reset Time：",
        components3:"Fan count ticket status：",
        components4:"Turn on",
        components5:"Complete",
        components6:"Close",
        components7:"Fan count ticket reset data time：",
        components8:"Note：",
        components9:"Invitation Code：",
        components10:"Live link：",
        components11:"Click to copy",
        components12:"Download APP",
        components13:"Account ID",
        components14:"Account nickname",
        components15:"Please enter",
        components16:"Search",
        components17:"Reset",
        components18:"Turn on distribution",
        components19:"Close allocation",
        components20:"Batch reset",
        components21:"Total Fans After Ticket Reset (De-duplicated)：",
        components22:"Total Fans After Daily Reset (De-duplicated)：",
        components23:"Account",
        components24:"Profile-Image",
        components25:"Allocation status",
        components26:"Please set the account ID",
        components27:"Online",
        components28:"Offline",
        components29:"Daily New Fans / Daily Fan Limit",
        components30:"Account Link",
        components31:"Logs",
        components32:"Fans Detail",
        components33:"View",
        components34:"Fan count ticket has been",
        components35:"Complete",
        components36:"Close",
        components37:"Inoperable",
        components38:"Please choose to generate a live link account",
        components39:"Generate living chain",
        components40:"Live Link Management",
        components41:"Please select an account",
        components42:"Assignments",
        components43:"Are you sure you want to enable allocation?？",
        components44:"Are you sure you want to close allocation?？",
        components45:"Batch reset",
        components46:"Confirm reset",
        components47:"Successful operation",
        components48:"Close account allocation first and then delete it",
        components49:"Batch deletion",
        components50:"Are you sure you want to delete it?？",
        components51:"There is no eligible account, please choose again.",
        components52:"Individual",
        components53:"Online status",
        components54:"Copied successfully!",
    }
}