export const tableProps = {
  refTable:{
    type:String,
    default:''
  },
  loading: {
    type: Boolean,
    default: false
  },
  border: {
    type: Boolean,
    default: false
  },
  list: { // 列表数据
    type: Array,
    default: () => []
  },
  columns: { // 列数据
    type: Array,
    default: () => []
  },
  rowKey: {
    type: String,
    default: ''
  },
  treeProps: {
    type: Object,
    default: () => {}
  },
  text: {
    type: String,
    default: ''
  },
  background: {
    type: Boolean,
    default: true
  },
  total: {
    type: Number,
    default: 0
  },
  pageNum: {
    type: Number,
    default: 1
  },
  pageSize: {
    type: Number,
    default: 10
  },
  pageSizes: {
    type: Array,
    default: () => {
      return [10, 20, 30, 50]
    }
  },
  layout: {
    type: String,
    default: 'total, sizes, prev, pager, next, jumper'
  }
}
