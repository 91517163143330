export default {
  codeList1: 'Invitation Code',
  codeList2: 'Create New Invitation Code(Sub-account)',
  codeList3: 'Invitation Code：',
  codeList4: 'Please enter',
  codeList5: 'Status：',
  codeList6: 'Note：',
  codeList7: 'Please enter',
  codeList8: 'Search',
  codeList9: 'Reset',
  codeList10: 'Invitation Code',
  codeList11: 'Available Port / Total Port',
  codeList12: 'Unlimited',
  codeList13: 'Status',
  codeList14: 'Operate',
  codeList15: 'View',
  codeList16: 'Share',
  codeList17: 'Edit',
  codeList18: 'Fan Count Ticket',
  codeList19: 'Delete',
  codeList20: 'New',
  codeList21: 'Share Invitation Code',
  codeList22: 'Delete task',
  codeList23: 'Are you sure you want to delete it?？',
  codeList24: 'Successful operation',
  codeList25: 'Copied successfully!',
  codeList26: 'Fan Count Ticket',
  codeList27: 'A Fan count ticket has been opened',
  codeList28: 'Number of Live Links',
  codeList29: 'Creation Time',
  codeList30: 'Online Account',
  codeList31: 'Total Number of Fans (Duplication Removed)',
  codeList32: 'New Fans Count for Today(Duplication Removed)',
  codeList33: 'Note',
  codeList34: 'Status',
  codeList35: 'Current mode',

  codeList36: 'Share Password',
  codeList37: 'Enable Counting',
  codeList38: 'Reset Data',
  codeList39:
    'After confirming this operation, all new follower data will be cleared and converted to base followers. The total number of new followers will change. This action is irreversible. Do you want to continue?',

  codeList40: 'Enable Counter Feature',
  codeList41: 'Yes',
  codeList42: 'No',

  codeList43: 'Sharing can only be done after enabling the counter function',
  codeList44: 'The current package does not support',
  codeList45: 'Account Type',
  codeList46: 'Default',
  codeList47: 'Create',
  codeList48: 'Character Usage',
};
